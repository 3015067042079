@import url('http://fonts.cdnfonts.com/css/gotham');

@font-face {
  font-family: Gotham-Light;
  src: url(../public/fonts/Gotham-Light.ttf);
}

*{
  font-family: 'Gotham Serif', sans-serif;
}

.gotham-light{
  font-family: 'Gotham-Light';
}

svg{
  width: 100%;
  height: auto;
}

text tspan{
  fill: #000 !important;
}

.text-gold{
  color: #c9920e;
}

.bg-gold{
  background-color: #c9920e;
}

.opacity-navbar{
  background-color: rgba(0, 0, 0, .8);
}

.bg-home{
  background-image: url(../public/images/home/VistaPrincipal.png);
}

.bg-tdm{
  background-image: url(../public/images/home/VistaTDM.png);
}

.bg-gracias{
  background-image: url(../public/images/gracias/graciasTerrazas.jpg);

}

.bg-cover{
  background-position: center;
  background-size: cover;
}

.h-85px{
  height: 85px;
}

.z-10{
  z-index:10;
}

.z-11{
  z-index:11 !important;
}

nav{
  z-index:12;
}

nav .container{
  padding: 0;
}

.text-justify{
  text-align: justify;
}

.min-vh-header{
  height: calc(100vh - 85px);
}

.border-b-gold{
  border-bottom: 2px solid #c9920e;
}

.m-85px{
  margin-top: 85px;
}

.min-vh-50{
  min-height: 50vh;
}

.w-formulario{
  width: 400px;
}

.rotate-360{
  transform: rotate(270deg) !important;
  right: -115px !important;
}

.position-formulario{
  top: 30%;
  right: -390px;
  z-index: 3;
  transition: all 1s ease;
}

.nowrap{
  white-space: nowrap;
}

.position-formulario.active{
  right: 0;
}

.icons-size{
  width: 50px;
  height: 50px;
}

.vh-50{
  height: 50vh;
}

.p-90{
  padding-top: 230px;
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 90px;

}

.ls-4{
  letter-spacing: 4px;
}

.fs-14px{
  font-size: 20px;
}

.vh-departametos{
  height: 100vh;
}

.slick-prev,
.slick-next {
  position: absolute;
  transform: translateY(-50%);
  z-index: 2;
  width: 50px !important;
  height: 35px !important;
}

.slick-prev {
  top: 20% !important;
  left: 47.5% !important;
  transform: translate(-50%, -50%);
}

.slick-next {
  top: 80% !important;
  right: 47.5% !important;
  transform: translate(5%, -50%);
}

.carousel-vh-100 img{
  height: 100vh !important;
}

.img-desarrollador{
  height: 50vh;
}

.react-tel-input .form-control {
  width: 100% !important;
}


.contact-div {
  background-color: #c9920ede;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute; 
}

.tipologia-top{
  position: absolute;
  top: 0;
  padding: 0px 150px 0;
}

.etiqueta-tipologia{
  position: absolute;
  bottom: 50px;
  right: 0px;
  padding: 0px 15px 0px;
  width: auto;
  height: 0px;
  border-right: 0px solid transparent;
  border-left: 30px solid transparent;
  border-bottom: 36px solid #000;
  color: #b99125 !important;
}

.st0{fill:#B99125;}

/* Para pantallas grandes y más grandes */
@media (max-width: 992px) {
  .contact-div {
    transform: translateY(-0%);
    position: static; 
  }
  .vh-departametos{
    height: auto;
  }

  .tipologia-top{
    position: static;
    top: 0;
    padding: 0;
  }
}

/***************************SCROLL BAR*******************************/

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}



/***************************SVG*******************************/

#grupo1,
#grupo2,
#grupo3,
#grupo4,
#grupo5,
#capa1,
#capa2,
#capa3{
  opacity: 0;
}

#grupo1:hover,
#grupo2:hover,
#grupo3:hover,
#grupo4:hover,
#grupo5:hover{
  opacity: 100%;
}

#capa1:hover,
#capa2:hover,
#capa3:hover{
  opacity: 80%;
}

#svg1 .cls-7{
  fill: #e7cc8f
}

#svg1 .cls-10{
  fill: #9dacd5
}


/***************************kuko*******************************/
.promo_departamentos{
  background-image: url(../public/images/departamentos/promocion.webp);
  -webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	background-position: center center;
  height: 470px;
}
.logo_desarrollos_180{
  width: 180px;
}
.active{
  background-color: #c9920e!important;
}
.logo_desarrollos_150{
  width: 150px;
}
.logo_espacio{
  padding: 20px;
}
.m_icon{
  width: 100%;
  display: inline;
}
.redes{
  position: fixed;
  bottom: 40px;
  right: 40px;
}
.margin_icon{
  margin-bottom: 10px;
}
.margin_bottom{
  margin-bottom: 0px;
}
.margin_bottom_plano{
  margin-bottom: 0px;
}
.margin_logo{
  margin-left: 0px;
}
.margin_cotiza{
  padding-right: 10px;
}
.margin_tipologia{
  margin-top: 0px;
}
.img_tipologia{
  width: 345px;
}
.back_blanco{
  background-color:#ffff!important;
}
.mt-5k{
  padding-top: 2em;
}
@media (max-width: 768px)  {
  .logo_desarrollos_180{
    width: 110px;
  }
  .logo_desarrollos_150{
    width: 90px;
  }
  .logo_espacio{
    padding: 0px;
  }
  .margin_movil{
    margin-left: 30%;
  }
  .redes{
    position: fixed;
    bottom: 30px;
    right: 10px;
  }
  .margin_bottom{
    margin-bottom: 50px;
  }
  .margin_logo{
    margin-left: 30px;
  }
  .img_tipologia{
    width: 345px;
  }
  .ps-5{
  padding-left: 0rem !important;
  }
  .margin_bottom_plano{
    margin-bottom: 50px;
  }
  .fs-14px{
    font-size: 14px;
  }
  .p-90{
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  
  }
  td{
    padding: 0px;
  }
  .mt-5k{
    padding-top: 0em;
  }
  .margin_tipologia{
    margin-top: 100px;
  }
  .etiqueta-tipologia{
    position: absolute;
    bottom: -20px!important;
    right: 0px;
    padding: 0px 15px 0px;
    width: auto;
    height: 0px;
    border-right: 0px solid transparent;
    border-left: 30px solid transparent;
    border-bottom: 36px solid #000;
    color: #b99125 !important;
  }
  .promo_departamentos{
    background-image: url(../public/images/departamentos/promocion_movil.webp);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center center;
    height: 700px;
  }
  @media screen and (min-width: 990px) {
    .ocultar_desktop {
        display: none;
    }
  }
  @media screen and (max-width: 991px) {
    .ocultar_movil {
        display: none;
    }
    .bg-home{
      background-image: url(../public/images/home/tdm-promo-movil.webp);
    }
  }

}