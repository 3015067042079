.third-sect-home { position: relative; overflow-x: hidden !important;}
.third-sect-home p.number { color: #c9920e; font-size: 76px; font-family: 'Gotham-Bold'; float: left; }
.third-sect-home p.txt-residencias { font-size: 32px; font-family: 'Gotham-Bold'; color: #1d1d1b; text-transform: uppercase; position: relative; left: 20px; width: 384px; line-height: 32px; top: 24px; }
.third-sect-home .cant-residencias { padding: 120px; position: absolute; top: 0px; left: 0px; z-index: 5; padding-bottom: 0px;}
.third-sect-home .residencial-hover-desktop {padding: 0px;overflow: hidden;}
.third-sect-home .residencial-hover-desktop img { margin: 0 auto; display: table; position: relative; width: 100%;}
.third-sect-home .change-view{cursor: pointer;}
.third-sect-home .trapecio-c-v { position: absolute; right: 120px; top: 100px; width: 340px; height: 0px; border-right: 210px solid transparent; border-left: 0px solid transparent; border-bottom: 190px solid #c9920e; z-index: 1; }
.third-sect-home .change-view img.miniatura-vista { width: 90%; position: relative; top: 10px; left: 10px; }
.third-sect-home .trapecio-interno { width: 147px; height: 0px; border-right: 0px solid transparent; border-left: 9px solid transparent; border-bottom: 5px solid #c8942c; position: absolute; top: -5px; -webkit-transform: rotate(-138deg); transform: rotate(-138deg); left: -18px; -webkit-transform-origin: right bottom; transform-origin: right bottom; }
.third-sect-home img.change-view-icon { -webkit-animation: bckView 5s infinite; -moz-animation: bckView 5s infinite; -o-animation: bckView 5s infinite; animation: bckView 5s infinite; position: absolute; width: 44px; height: 40px; right: 40px; top: 40px; background: rgba(255, 177, 2, 0.7); border-radius: 100%; padding: 4px; }
.third-sect-home p.disclaimer { position: absolute; bottom: 0px; padding: 0px 50px; max-width: 400px; font-size: 8px !important; }

@-webkit-keyframes bckView {
  	0%{background: rgba(255, 177, 2, 0.7);}
	30%{background: rgba(255, 177, 2, 0);}
	50%{background: rgba(255, 177, 2, 0.7);}
	80%{background: rgba(255, 177, 2, 0);}
	100%{background: rgba(255, 177, 2, 0.7);}
}
@-moz-keyframes bckView {
  	0%{background: rgba(255, 177, 2, 0.7);}zz
	30%{background: rgba(255, 177, 2, 0);}
	50%{background: rgba(255, 177, 2, 0.7);}
	80%{background: rgba(255, 177, 2, 0);}
	100%{background: rgba(255, 177, 2, 0.7);}
}
@-o-keyframes bckView {
  	0%{background: rgba(255, 177, 2, 0.7);}
	30%{background: rgba(255, 177, 2, 0);}
	50%{background: rgba(255, 177, 2, 0.7);}
	80%{background: rgba(255, 177, 2, 0);}
	100%{background: rgba(255, 177, 2, 0.7);}
}
@keyframes bckView {
  	0%{background: rgba(255, 177, 2, 0.7);}
	30%{background: rgba(255, 177, 2, 0);}
	50%{background: rgba(255, 177, 2, 0.7);}
	80%{background: rgba(255, 177, 2, 0);}
	100%{background: rgba(255, 177, 2, 0.7);}
}

.third-sect-home .bullets-frontal-residencial,.third-sect-home .bullets-posterior-residencial { position: absolute; width: 100%; height: 100%; top: 0px; left: 0px;}
.third-sect-home .bullets-posterior-residencial {display: none;}
.third-sect-home .bullet-click { cursor:pointer;-webkit-animation: bckView 4s infinite; -moz-animation: bckView 4s infinite; -o-animation: bckView 4s infinite; animation: bckView 4s infinite; position: absolute; width: 50px; height: 50px; background: rgba(255, 177, 2, 0.7); border-radius: 100%; padding: 4px; }
.third-sect-home .image-residencial { position: relative; width: 1920px; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); }
.third-sect-home .bullet-click.bullet-1 { top: 330px; left: 445px; }
.third-sect-home .bullet-click.bullet-2 { top: 540px; left: 470px; }
.third-sect-home .bullet-click.bullet-3 { top: 550px; left: 1235px; }
.third-sect-home .bullet-click.bullet-4 { top: 301px; left: 947px; }
.third-sect-home .bullet-click.bullet-5 { top: 470px; left: 590px; }
.third-sect-home .bullet-click.bullet-6 { top: 470px; left: 1250px; }
.third-sect-home .bullet-click.bullet-7 { top: 720px; left: 440px; }
.third-sect-home .bullet-click.bullet-8 { top: 840px; left: 1360px; }

.third-sect-home .images-hover { position: absolute; width: 100%; height: 100%; top: 0px; left: 0px; }
.third-sect-home .images-hover img { position: absolute !important; width: 100% !important; height: 100%; top: 0px; left: 0px; display: none;}
.third-sect-home .images-hover img.show{    display: inherit;}
.third-sect-home .conoce-nuestros-depas-link{display: none;}
.third-sect-home .conoce-nuestros-depas-link a { font-size: 12px; font-family: 'Gotham-Bold'; color: #1d1d1b; text-transform: uppercase; left: 0px; width: 177px; line-height: initial; top: unset; position: absolute; bottom: 100px; background: #c9920e; padding: 10px 20px 6px 29px; text-align: left; border-top-right-radius: 30px; border-bottom-right-radius: 30px; }
.third-sect-home .bullet-click .info-depa p { margin: 0px; font-size: 13px;}
.third-sect-home .bullet-click .info-depa { position: absolute; top: 0px; background: #ca942c; font-family: 'Gotham-Light'; color: white; width: 198px; padding: 20px 20px 5px; }
.third-sect-home .bullet-click .info-depa .title-f-d { text-transform: uppercase; }
.third-sect-home .bullet-click .info-depa:after { content: ''; position: absolute; left: 0px; top: 100%; width: 100%; height: 0; border-top: solid 23px #ca942c; border-left: solid 25px transparent; border-right: solid 0px transparent; }
.third-sect-home .bullet-click .depto-360-click { position: absolute; width: 62px; top: 0px; right: -96px; }
.third-sect-home .bullet-click .depto-360-click p { color: black; font-size: 13px; text-align: center; position: absolute; bottom: -23px; font-family: 'Gotham-Bold'; width: 101px; left: -17px; }

.third-sect-home .bullet-click.bullet-1 .info-depa { left: 482px; top: -112px; }
.third-sect-home .bullet-click.bullet-2 .info-depa { left: 1100px; top: -92px; }
.third-sect-home .bullet-click.bullet-2 .depto-360-click { top: unset; bottom: -95px; left: unset; right: 23px; }
.third-sect-home .bullet-click.bullet-3 .info-depa {top: -133px;left: 122px;}
.third-sect-home .bullet-click.bullet-4 .info-depa { top: -109px; left: -27px; }
.third-sect-home .bullet-click.bullet-5 .info-depa { top: -126px; left: -200px; }
.third-sect-home .bullet-click.bullet-6 .info-depa { left: 150px; }
.third-sect-home .bullet-click.bullet-7 .depto-360-click { top: unset; bottom: -95px; right: unset; left: 56px; }
.third-sect-home .bullet-click.bullet-7 .info-depa { left: -234px; top: -281px; }
.third-sect-home .bullet-click.bullet-8 .info-depa { top: -250px; left: 50px; }
.third-sect-home .bullet-click.show-info .info-depa {display: inherit;}





@media screen and (max-width: 1920px){
	.third-sect-home .image-residencial {left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); }
}

@media screen and (max-width: 1600px){
	.third-sect-home .image-residencial { width: 1600px; margin-top: 30px; }
	.third-sect-home .bullet-click.bullet-1 { top: 269px; left: 367px; }
	.third-sect-home .bullet-click.bullet-2 { top: 435px; left: 390px; }
	.third-sect-home .bullet-click.bullet-3 { top: 460px; left: 1020px; }
	.third-sect-home .bullet-click.bullet-4 { top: 250px; left: 795px; }
	.third-sect-home .bullet-click.bullet-5 { top: 400px; left: 490px; }
	.third-sect-home .bullet-click.bullet-6 { top: 390px; left: 1030px; }
	.third-sect-home .bullet-click.bullet-7 { top: 600px; left: 369px; }
	.third-sect-home .bullet-click.bullet-8 { top: 736px; left: 1140px; }

	.third-sect-home .bullet-click.bullet-1 .info-depa { left: 376px; top: -131px; }
	.third-sect-home .bullet-click.bullet-2 .info-depa { left: 918px; top: -72px; }
}

@media screen and (max-width: 1400px){
	.third-sect-home .image-residencial { width: 1400px;}

	.third-sect-home .bullet-click.bullet-1 { top: 235px; left: 315px; }
	.third-sect-home .bullet-click.bullet-2 { top: 380px;left: 335px; }
	.third-sect-home .bullet-click.bullet-3 { top: 395px;left: 880px; }
	.third-sect-home .bullet-click.bullet-4 { top: 209px;left: 693px; }
	.third-sect-home .bullet-click.bullet-5 { top: 343px; left: 426px; }
	.third-sect-home .bullet-click.bullet-6 { top: 340px; left: 895px; }
	.third-sect-home .bullet-click.bullet-7 { top: 525px; left: 300px; }
	.third-sect-home .bullet-click.bullet-8 { top: 736px; left: 1140px; }
	.third-sect-home .bullet-click.bullet-8 { top: 600px; left: 990px; }

	.third-sect-home .trapecio-c-v { width: 262px; border-right: 133px solid transparent; border-left: 0px solid transparent; border-bottom: 170px solid #c9920e; }
	.third-sect-home .change-view img.miniatura-vista { width: 79%;}
	.third-sect-home .trapecio-interno { -webkit-transform: rotate(-128deg); transform: rotate(-128deg); }
	.third-sect-home img.change-view-icon { right: 69px; top: 20px; }
	.third-sect-home .bullet-click .info-depa { font-size: 15px; }
	.third-sect-home .bullet-click .info-depa { font-size: 15px; width: 158px; }
	.third-sect-home .bullet-click.bullet-1 .info-depa { left: 330px; top: -118px; }
	.third-sect-home .bullet-click.bullet-3 .info-depa { top: -112px; left: 109px; }
	.third-sect-home .bullet-click.bullet-2 .info-depa { left: 818px; top: -72px; }
	.third-sect-home .bullet-click.bullet-7 .info-depa { left: -194px; top: -281px; }


}


@media screen and (max-width: 1200px){
	.third-sect-home .trapecio-c-v{right: 50px;}
}

@media screen and (max-width: 1250px){
	.third-sect-home .image-residencial { width: 1250px; margin-top: 50px; }
	.third-sect-home .bullet-click {width: 40px;height: 40px;}
	.third-sect-home .bullet-click.bullet-1 {top: 212px;left: 289px;}
	.third-sect-home .bullet-click.bullet-2 { top: 340px; left: 301px; }
	.third-sect-home .bullet-click.bullet-3 { top: 355px; left: 795px; }
	.third-sect-home .bullet-click.bullet-4 { top: 195px; left: 620px; }
	.third-sect-home .bullet-click.bullet-5 { top: 300px; left: 383px; }
	.third-sect-home .bullet-click.bullet-6 { top: 300px; left: 838px; }
	.third-sect-home .bullet-click.bullet-7 { top: 467px; left: 277px; }
	.third-sect-home .bullet-click.bullet-8 { top: 540px; left: 880px; }

	.third-sect-home .bullet-click .info-depa { font-size: 14px; width: 127px; padding: 20px 13px 5px; }
	.third-sect-home .bullet-click .depto-360-click { position: absolute; width: 54px; top: 0px; right: -82px; }
	.third-sect-home .bullet-click .depto-360-click p { bottom: -38px; width: 60px; left: -4px; font-size: 12px; }
	.third-sect-home .bullet-click.bullet-4 .info-depa { top: -100px; left: 0px; }
	.third-sect-home .bullet-click.bullet-5 .info-depa { top: -104px; left: -200px; }
	.third-sect-home .bullet-click.bullet-6 .info-depa { left: 85px; }
	.third-sect-home .bullet-click.bullet-7 .info-depa { left: -152px; top: -281px; }
	.third-sect-home .bullet-click.bullet-8 .info-depa { top: -207px; left: 27px; }
	.third-sect-home .bullet-click.bullet-2 .info-depa { left: 734px; top: -72px; }
}

@media screen and (max-width: 1100px){

	.third-sect-home .image-residencial { width: 1100px; margin-top: 80px; }
	
	.third-sect-home .bullet-click.bullet-1 { top: 183px; left: 251px; }
	.third-sect-home .bullet-click.bullet-2 { top: 296px; left: 265px; }
	.third-sect-home .bullet-click.bullet-3 { top: 314px; left: 703px; }
	.third-sect-home .bullet-click.bullet-4 { top: 166px; left: 541px; }
	.third-sect-home .bullet-click.bullet-5 { top: 265px; left: 334px; }
	.third-sect-home .bullet-click.bullet-6 { top: 266px; left: 735px; }
	.third-sect-home .bullet-click.bullet-7 { top: 415px; left: 240px; }
	.third-sect-home .bullet-click.bullet-8 { top: 466px; left: 770px; }

	.third-sect-home .trapecio-c-v { width: 219px; border-right: 133px solid transparent; border-left: 0px solid transparent; border-bottom: 149px solid #c9920e; }
	.third-sect-home .change-view img.miniatura-vista { width: 66%; }
	.third-sect-home img.change-view-icon { right: 112px; top: 16px; }
	.third-sect-home .trapecio-interno { -webkit-transform: rotate(-128deg); transform: rotate(-128deg); left: -59px; }
	.third-sect-home .bullet-click.bullet-1 .info-depa { left: 303px; top: -87px; }
	.third-sect-home .bullet-click.bullet-2 .info-depa { left: 634px; top: -72px; }


}



@media screen and (max-width: 991px){
	
	.third-sect-home .residencial-hover-desktop {overflow: unset;}
	.third-sect-home .image-residencial { width: 100%;left: unset; -webkit-transform: unset; transform: unset;margin-top: -100px;}
	.third-sect-home .cant-residencias { position: relative; padding-bottom: 0px; }
	.third-sect-home .css-for-mob { width: 100vw; }
	.third-sect-home .black-text-trapecio, .fourth-sect-home .black-text-trapecio { bottom: 1px; }
	.third-sect-home .trapecio-c-v { position: relative; left: 50%; -webkit-transform:translateX(-50%);transform: translateX(-50%); top: unset; margin-bottom: 50px; margin-top: 100px; }
	.third-sect-home {overflow: hidden;}
	.third-sect-home .image-residencial { width: 991px !important; min-width: 991px; position: relative; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); }

	.third-sect-home .bullet-click.bullet-1 .info-depa { left: 277px; top: -100px; }
	.third-sect-home .bullet-click.bullet-2 .info-depa { left: 434px; top: -214px; }
	.third-sect-home .bullet-click.bullet-3 .info-depa { top: -153px; left: -22px; }
	.third-sect-home .bullet-click.bullet-4 .info-depa { top: -72px; left: 56px; }
	.third-sect-home .bullet-click.bullet-6 .info-depa { left: 59px; top: -100px; }
	.third-sect-home .bullet-click.bullet-6 .depto-360-click { right: unset; top: unset; bottom: -84px; left: 38px; }
	.third-sect-home .bullet-click.bullet-7 .info-depa { left: -138px; top: -281px; }
	.third-sect-home .bullet-click.bullet-8 .info-depa { left: 25px; top: -262px; }
	.third-sect-home .bullet-click.bullet-8 .depto-360-click { right: unset; top: unset; bottom: -84px; left: 38px; }
	.third-sect-home p.disclaimer { bottom: 37px; display: none;}
}
@media screen and (max-width: 991px) and (max-height: 500px) {

	.third-sect-home .bullet-click { width: 30px; height: 30px; }
	.third-sect-home .bullet-click.bullet-1 { top: 170px; left: 230px; }
	.third-sect-home .bullet-click.bullet-2 { top: 270px; left: 240px; }
	.third-sect-home .bullet-click.bullet-3 { top: 280px; left: 639px; }
	.third-sect-home .bullet-click.bullet-4 { top: 155px; left: 489px; }
	.third-sect-home .bullet-click.bullet-5 { top: 239px; left: 306px; }
	.third-sect-home .bullet-click.bullet-6 { top: 243px; left: 662px; }
	.third-sect-home .bullet-click.bullet-7 { top: 381px; left: 221px; }
	.third-sect-home .bullet-click.bullet-8 { top: 419px; left: 701px; }
}



@media screen and (max-width: 860px){
	.third-sect-home .image-residencial { width: 860px !important;min-width: 860px; }

	.third-sect-home .bullet-click.bullet-1 { top: 145px; left: 198px; }
	.third-sect-home .bullet-click.bullet-2 { top: 232px; left: 206px; }
	.third-sect-home .bullet-click.bullet-3 { top: 243px; left: 544px; }
	.third-sect-home .bullet-click.bullet-4 { top: 130px; left: 429px; }
	.third-sect-home .bullet-click.bullet-5 { top: 209px; left: 264px; }
	.third-sect-home .bullet-click.bullet-6 { top: 207px; left: 571px; }
	.third-sect-home .bullet-click.bullet-7 { top: 325px; left: 189px; }
	.third-sect-home .bullet-click.bullet-8 { top: 364px; left: 607px; }

	.third-sect-home .bullet-click.bullet-1 .info-depa { left: 322px; top: -79px; }
	.third-sect-home .bullet-click.bullet-2 .info-depa { left: 373px; top: -214px; }
	.third-sect-home .bullet-click.bullet-7 .info-depa { left: -131px; top: -281px; }
}

@media screen and (max-width: 767px){
	.third-sect-home .image-residencial { width: 767px !important;min-width: 767px; }

	.third-sect-home .bullet-click.bullet-1 { top: 127px; left: 176px; }
	.third-sect-home .bullet-click.bullet-2 { top: 207px; left: 186px; }
	.third-sect-home .bullet-click.bullet-3 { top: 214px; left: 488px; }
	.third-sect-home .bullet-click.bullet-4 { top: 112px; left: 374px; }
	.third-sect-home .bullet-click.bullet-5 { top: 177px; left: 233px; }
	.third-sect-home .bullet-click.bullet-6 { top: 183px; left: 513px; }
	.third-sect-home .bullet-click.bullet-7 { top: 284px; left: 166px; }
	.third-sect-home .bullet-click.bullet-8 {top: 318px;left: 538px;}

	.third-sect-home .image-residencial { margin-top: -69px; }
	.third-sect-home .bullet-click.bullet-1 .info-depa { left: 287px; top: -79px; }
	.third-sect-home .bullet-click.bullet-2 .info-depa { left: 352px; top: -214px; }
	.third-sect-home .bullet-click.bullet-4 .info-depa { top: -54px; left: 56px; }
	.third-sect-home .bullet-click.bullet-5 .info-depa { top: -134px; left: -100px; }
	.third-sect-home .bullet-click.bullet-6 .info-depa { left: 23px; top: -109px; }
	.third-sect-home .bullet-click.bullet-7 .info-depa { left: -76px; top: -281px; }
	.third-sect-home .bullet-click.bullet-7 .depto-360-click { top: unset; bottom: -95px; right: unset; left: 14px; }

}


@media screen and (max-width: 625px){
	.third-sect-home .cant-residencias { padding: 0px; margin: 0 auto; display: table; padding-top: 70px; padding-bottom: 20px; }
}

@media screen and (max-width: 600px){
	.third-sect-home .image-residencial { width: 600px !important;min-width: 600px; }

	.third-sect-home .bullet-click.bullet-1 { top: 160px; left: 100px; }
	.third-sect-home .bullet-click.bullet-1 .info-depa { left: 0px; top: -130px; }
	.third-sect-home .bullet-click.bullet-2 { top: 220px; left: 130px; }
	.third-sect-home .bullet-click.bullet-2 .info-depa { left: -20px; top: -190px; }
	.third-sect-home .bullet-click.bullet-2 .depto-360-click { top: unset; bottom: 20px; left: unset; right: -90px; }
	.third-sect-home .bullet-click.bullet-3 { top: 214px; left: 280px; }
	.third-sect-home .bullet-click.bullet-3 .info-depa { top: -180px; left: -170px; }
	.third-sect-home .bullet-click.bullet-4 { top: 150px; left: 200px; }
	.third-sect-home .bullet-click.bullet-4 .info-depa { top: -120px; left: -100px; }
	.third-sect-home .bullet-click.bullet-5 { top: 190px; left: 110px; }
	.third-sect-home .bullet-click.bullet-5 .info-depa { top: -160px; left: -10px; }
	.third-sect-home .bullet-click.bullet-6 { top: 200px; left: 250px; }
	.third-sect-home .bullet-click.bullet-6 .info-depa { left: -170px; top: -190px; }
	.third-sect-home .bullet-click.bullet-6 .depto-360-click { top: unset; bottom: 30px; left: unset; right: -90px; }
	.third-sect-home .bullet-click.bullet-7 { top: 240px; left: 100px; }
	.third-sect-home .bullet-click.bullet-7 .info-depa { left: -10px; top: -220px; }
	.third-sect-home .bullet-click.bullet-7 .depto-360-click { top: unset; bottom: 10px; left: unset; right: -90px; }
	.third-sect-home .bullet-click.bullet-8 {top: 280px;left: 50px;}
	.third-sect-home .bullet-click.bullet-8 .depto-360-click { top: unset; bottom: 20px; left: unset; right: -90px; }
	/*.third-sect-home .bullets-frontal-residencial, .third-sect-home .bullets-posterior-residencial {  display: none !important;K } */
	.third-sect-home .conoce-nuestros-depas-link{display: inherit;}
}

@media screen and (max-width: 530px){
	.third-sect-home .image-residencial { width: 530px !important;min-width: 530px; }
}

@media screen and (max-width: 500px){

	.third-sect-home p.txt-residencias { font-size: 25px; width: 300px; top: 12px;}
	.third-sect-home p.number { font-size: 60px; }
	.third-sect-home .image-residencial { margin-top: -60px !important; }

}

@media screen and (max-width: 460px){
	.third-sect-home .image-residencial { width: 460px !important;min-width: 460px; }
}

@media screen and (max-width: 440px){
	.third-sect-home .image-residencial { width: 400px !important;min-width: 400px; }
}

@media screen and (max-width: 374px){
	.row .first-sect-home .texto-f-s-home h1 { font-size: 20px; }
	/*.row .first-sect-home .texto-f-s-home { width: 301px; }*/

	.third-sect-home p.number { font-size: 55px; }
	.third-sect-home p.txt-residencias { font-size: 20px; width: 235px; top: 16px; line-height: 22px; }
}

